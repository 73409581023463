import { ReactNode, useEffect, useRef, useState } from 'react';
import { useDragDropManager } from 'react-dnd';

type AutoScrollProviderProps = {
  children: ReactNode;
  elementHeight: number;
  treshold: number;
  scrollSpeed: number;
  browserZoomTreshold: number;
};

type ScrollDirType = -1 | 0 | 1;

export default function AutoScrollProvider({
  children,
  elementHeight,
  treshold,
  scrollSpeed,
  browserZoomTreshold,
}: AutoScrollProviderProps) {
  const [scrollDir, setScrollDir] = useState<ScrollDirType>(0);
  const scrollSpeedRef = useRef(1);
  const monitor = useDragDropManager().getMonitor();

  const scrollEl = document.documentElement;

  const getIsTop = () => scrollEl.scrollTop === 0;

  const getIsBottom = () => {
    return scrollEl.clientHeight + scrollEl.scrollTop >= scrollEl.scrollHeight - browserZoomTreshold;
  };

  function useInterval(callback: () => void, delay: number, condition: boolean) {
    const savedCallback = useRef(Function.prototype);

    useEffect(() => {
      savedCallback.current = callback;
    }, [callback]);

    useEffect(() => {
      if (!condition) {
        return undefined;
      }

      function tick() {
        savedCallback.current();
      }

      const id = setInterval(tick, delay);

      return () => {
        clearInterval(id);
      };
    }, [delay, condition]);
  }

  useInterval(
    () => {
      if (scrollDir === 0 || (scrollDir < 0 && getIsTop()) || (scrollDir > 0 && getIsBottom())) {
        setScrollDir(0);
        return;
      }

      const power = scrollSpeed * scrollDir * Math.min(1, Math.abs(scrollSpeedRef.current));
      scrollEl.scrollBy(0, power);
    },
    16,
    scrollDir !== 0,
  );

  useEffect(() => {
    return monitor.subscribeToOffsetChange(() => {
      const offset = monitor.getSourceClientOffset()?.y;
      if (!offset) {
        setScrollDir(0);
        return;
      }

      const windowHeight = window.innerHeight;

      let direction: ScrollDirType = 0;
      scrollSpeedRef.current = 1;

      if (offset > windowHeight - (treshold + elementHeight)) {
        scrollSpeedRef.current = (offset - (windowHeight - (treshold + elementHeight))) / treshold;
        direction = 1;
      }
      if (offset < treshold) {
        scrollSpeedRef.current = (treshold - offset) / treshold;
        direction = -1;
      }

      setScrollDir(direction);
    });
  }, [elementHeight, monitor, treshold]);

  return <div>{children}</div>;
}
